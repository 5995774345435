<div class="shipments-preview" [formGroup]="formGroup">
    <table *ngIf="worldShipShipmentsControls.length"
           datatable
           [dtOptions]="dtOptions"
           [dtTrigger]="dtTrigger"
           class="stripe">
        <thead>
        <tr class="grid-head text-nowrap">
            <th class="text-center">{{ 'Void'|translate }}</th>
            <th>{{ 'ServiceType'|translate }}</th>
            <th>{{ 'worldShipUpload.shipperUPS'|translate }} #</th>
            <th class="hide-me">{{ 'CompanyName'|translate }}</th>
            <th>{{ 'Tracking'|translate }} #</th>
            <th class="text-center">{{ 'worldShipUpload.ref1'|translate }}</th>
            <th class="text-center">{{ 'worldShipUpload.ref2'|translate }}</th>
            <th class="text-center">{{ 'worldShipUpload.ref3'|translate }}</th>
            <th>{{ 'worldShipUpload.billToUPS'|translate }} #</th>
            <th class="text-center">{{ 'worldShipUpload.dateCreated'|translate }}</th>
            <th>{{ 'worldShipUpload.billtoPPI'|translate }} #</th>
            <th class="text-center">{{ 'worldShipUpload.exceptions'|translate }}</th>
            <th class="text-center"></th>
        </tr>
        </thead>
        <tfoot></tfoot>
        <tbody formArrayName="worldShipShipments">
        <tr *ngFor="let shipmentControl of worldShipShipmentsControls; let i = index">
            <td class="text-center">{{ shipmentControl.value.Void }}</td>
            <td class="blockable">
                <div [formGroupName]="i">
                    <mat-form-field [subscriptSizing]="'dynamic'">
                        <mat-select formControlName="ServiceType">
                            <mat-option *ngFor="let serviceType of serviceTypes"
                                        [value]="getServiceValue(serviceType.ServiceName)">
                                {{ getDisplayedServiceName(serviceType.ServiceName) }}
                            </mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
                <upsc-element-blocker *ngIf="isServiceTypesLoading"></upsc-element-blocker>
            </td>
            <td>{{ shipmentControl.value.ShipperUpsNumber }}</td>
            <td class="hide-me">{{ shipmentControl.value.CompanyName }}</td>
            <td>{{ shipmentControl.value.TrackingNumber }}</td>
            <td class="text-center">
                <div [formGroupName]="i">
                    <mat-form-field class="form-field--short" [subscriptSizing]="'dynamic'">
                        <input matInput formControlName="Ref1">
                    </mat-form-field>
                </div>
            </td>
            <td class="text-center">
                <div [formGroupName]="i">
                    <mat-form-field class="form-field--short" [subscriptSizing]="'dynamic'">
                        <input matInput formControlName="Ref2">
                    </mat-form-field>
                </div>
            </td>
            <td class="text-center">
                <div [formGroupName]="i">
                    <mat-form-field class="form-field--short" [subscriptSizing]="'dynamic'">
                        <input matInput formControlName="Ref3">
                    </mat-form-field>
                </div>
            </td>
            <td>{{ shipmentControl.value.BillToUpsNumber }}</td>
            <td class="text-center">
                <div [formGroupName]="i">
                    <mat-form-field class="date-picker" [subscriptSizing]="'dynamic'">
                        <input matInput [matDatepicker]="picker" formControlName="Created">
                        <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                        <mat-datepicker #picker></mat-datepicker>
                    </mat-form-field>
                </div>
            </td>
            <td class="text-center">{{ shipmentControl.value.BillToPpiNumber }}</td>
            <td class="text-center">
                <div *ngIf="shipmentControl.value.Exceptions?.length" class="exceptions">
                    <mat-icon>warning</mat-icon>
                    <span class="badge">{{ shipmentControl.value.Exceptions?.length }}</span>
                    <ul class="popover-content">
                        <li *ngFor="let exception of shipmentControl.value.Exceptions">{{ exception }}</li>
                    </ul>
                </div>
            </td>
            <td class="text-center">
                <button mat-raised-button type="button" (click)="removeShipment($event, i)">{{ 'Delete'|translate }}
                </button>
            </td>
        </tr>
        </tbody>
    </table>
</div>
