import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { WorldShipUploadComponent } from './world-ship-upload.component';
import { AuthGuardService } from '../shared/services/auth-guard/auth-guard.service';
import { LayoutComponent } from '../layout/layout.component';

@NgModule({
  imports: [RouterModule.forChild([
    {
      path: '',
      canActivate: [AuthGuardService],
      component: LayoutComponent,
      children: [
        {
          path: '',
          component: WorldShipUploadComponent,
        },
      ],
    },
  ])],
  exports: [RouterModule],
})
export class WorldShipUploadRoutingModule {
}
