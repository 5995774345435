import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DataTablesModule } from 'angular-datatables';
import { LayoutModule } from '../layout/layout.module';
import { S3FileDownloaderModule } from '../shared/components/s3-file-downloader/s3-file-downloader.module';
import { SharedModule } from '../shared/shared.module';
import { WorldShipUploadService } from './services/world-ship-upload.service';
import { ShipmentsPreviewComponent } from './shipments-preview/shipments-preview.component';
import { WorldShipUploadRoutingModule } from './world-ship-upload-routing.module';
import { WorldShipUploadComponent } from './world-ship-upload.component';
import { MatTabsModule } from '@angular/material/tabs';

@NgModule({
    imports: [
        WorldShipUploadRoutingModule,
        CommonModule,
        SharedModule,
        LayoutModule,
        FormsModule,
        ReactiveFormsModule,
        DataTablesModule,
        S3FileDownloaderModule,
        TranslateModule,
        MatTabsModule,
    ],
    declarations: [
        WorldShipUploadComponent,
        ShipmentsPreviewComponent,
    ],
    providers: [WorldShipUploadService],
    exports: [WorldShipUploadComponent],
})
export class WorldShipUploadModule {
}
