import { Injectable } from '@angular/core';
import { HttpClientService } from '../../core/services/http/http-client.service';
import moment from 'moment';
import SafeUrlAssembler from 'safe-url-assembler';
import { Observable, of } from 'rxjs';
import { IWorldShipShipmentCsv } from '../models/world-ship-shipment-csv.interface';
import { IWorldShipShipment } from '../models/world-ship-shipment.interface';
import { IWorldShipShipmentCsvDE } from '../models/world-ship-shipment-csv-de.interface';
import { WorldShipShipmentHeaders } from '../models/world-ship-shipment-headers';
import { ICarrierService } from '../../ship/models/carrier-service.interface';
import { ApiEndpointService } from '../../core/services/api-endpoint.service';

@Injectable()
export class WorldShipUploadService {
  constructor(private httpClientService: HttpClientService, private apiEndpointService: ApiEndpointService) {
  }

  /**
   * Converts the CSV shipments to API-based shipments.
   *
   * @param {IWorldShipShipmentCsv[] | IWorldShipShipmentCsvDE[]} csvShipments
   * @returns {IWorldShipShipment[]}
   */
  public refineWorldShipShipments(csvShipments: IWorldShipShipmentCsv[] | IWorldShipShipmentCsvDE[]): IWorldShipShipment[] {
    const isDE = this.isInterface<IWorldShipShipmentCsvDE[]>(csvShipments, 'SendungsinformationenStornoanzeige');

    if (isDE) {
      return this.getShipments(csvShipments, 'DE');
    }

    return this.getShipments(csvShipments, 'EN');
  }

  private getShipments(csvShipments: any, lang: string) {
    const shipments: IWorldShipShipment[] = [];

    csvShipments.forEach(
      (csvShipment) => {
        let csvDate = (+csvShipment[this.getShipmentHeader('ShipmentInformationCollectiondate', lang)]).toString().substring(0, 8);
        const year = csvDate.substring(0, 4);
        let month = csvDate.substring(4, 6);
        let date = csvDate.substring(6);

        if (month === '00') {
          month = '01';
        }

        if (date === '00') {
          date = '01';
        }

        csvDate = `${year}${month}${date}`;

        const createdDateMoment = moment(csvDate, ['YYYYMMDD', 'M/D/YYYY']);

        let createdDate = createdDateMoment.format('YYYY-MM-DD');
        if (!createdDateMoment.isValid()) {
          createdDate = null;
        }

        shipments.push({
          BillToPpiNumber: csvShipment[this.getShipmentHeader('ThirdPartyUPSAccountNumber', lang)]
          || csvShipment[this.getShipmentHeader('ThirdPartyUPSAccount', lang)],
          BillToUpsNumber: csvShipment[this.getShipmentHeader('ShipFromUPSAccountNumber', lang)],
          Created: createdDate,
          Ref1: csvShipment[this.getShipmentHeader('PackageReference1', lang)],
          Ref2: csvShipment[this.getShipmentHeader('PackageReference2', lang)],
          Ref3: csvShipment[this.getShipmentHeader('PackageReference3', lang)],
          ServiceType: csvShipment[this.getShipmentHeader('ShipmentInformationServiceType', lang)],
          ShipperUpsNumber: csvShipment[this.getShipmentHeader('ShipFromUPSAccountNumber', lang)],
          TrackingNumber: csvShipment[this.getShipmentHeader('PackageTrackingNumber', lang)].replace(/\s/g, ''),
          Void: csvShipment[this.getShipmentHeader('ShipmentInformationVoidIndicator', lang)],
          CompanyName: csvShipment['ShipToCompanyorName'],
          PackageWeight: csvShipment['PackageWeight']
        });
      },
    );

    return shipments;
  }

  private getShipmentHeader(key: string, lang: string): string {
    return WorldShipShipmentHeaders[key][lang];
  }

  private isInterface<T>(arg: any, uniqueProperty: string): arg is T {
    let obj = arg;
    if (Array.isArray(arg) && arg.length) {
      obj = arg[0];
    }

    return obj.hasOwnProperty(uniqueProperty);
  }

  /**
   * Saves WorldShip shipments.
   *
   * @param form
   * @returns {Observable<Response>}
   */
  public saveWorldShipShipments(form) {
    const data = {
      PackageReferenceColumn: form.reference,
      WorldshipShipments: form.shipments.worldShipShipments.map(
        (shipment) => {
          shipment.Created = moment(shipment.Created, 'YYYY-MM-DD').format('YYYYMMDD');
          return shipment;
        },
      ),
    };

    const endpoint = SafeUrlAssembler().template(this.apiEndpointService.getEndpoint('saveWorldShipShipments')).toString();
    return this.httpClientService.post<any>(endpoint, data);
  }

  /**
   * Uploads world-ship shipments CSV file.
   *
   * @param {string} reference
   * @param {FileList} files
   * @returns {Observable<any>}
   */
  public uploadWorldShipShipmentsCsv(reference: string, files: FileList): Observable<any> {
    const formData = new FormData();

    formData.append('reference', reference);

    if (files) {
      for (let i = 0; i < files.length; i = i + 1) {
        formData.append('files', files[i], files[i].name);
      }
    }

    const endpoint = SafeUrlAssembler().template(this.apiEndpointService.getEndpoint('saveWorldShipShipments')).toString();
    return this.httpClientService.postFormData<any>(endpoint, formData);
  }

  /**
   * Gets all service types.
   *
   * @param carrierCode
   * @returns {Observable<ICarrierService[]>}
   */
  public getServiceTypes(carrierCode: string): Observable<ICarrierService[]> {
    const endpoint = SafeUrlAssembler()
      .template(this.apiEndpointService.getEndpoint('getCarrierServices'))
      .param('carrierCode', carrierCode)
      .toString();
    return this.httpClientService.get<ICarrierService[]>(endpoint);
  }

  /**
   * Gets a fixed list of service types.
   *
   */
  public getServiceTypesFixed(carrierCode: string): Observable<ICarrierService[]> {
    let serviceTypes: ICarrierService[];

    switch (carrierCode.toLowerCase()) {
      case 'ups':
        serviceTypes = [
          {
            ServiceCode: '02',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: '2nd Day Air',
          },
          {
            ServiceCode: '59',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: '2nd Day Air AM',
          },
          {
            ServiceCode: '12',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: '3 Day Select',
          },
          {
            ServiceCode: '03',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: 'Ground',
          },
          {
            ServiceCode: '11-CA-DOM',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: 'Intra Canada Standard',
          },
          {
            ServiceCode: 'CA-DOM',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: 'Intra-Canada',
          },
          {
            ServiceCode: '01',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: 'Next Day Air',
          },
          {
            ServiceCode: '14',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: 'Next Day Air Early AM',
          },
          {
            ServiceCode: '13',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: 'Next Day Air Saver',
          },
          {
            ServiceCode: 'EU-DOM',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: 'UE Express',
          },
          {
            ServiceCode: 'UPSS',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: 'UPS Freight',
          },
          {
            ServiceCode: '11',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: 'Standard',
          },
          {
            ServiceCode: '08',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: 'Worldwide Expedited',
          },
          {
            ServiceCode: '07',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: 'Worldwide Express',
          },
          {
            ServiceCode: '54',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: 'Worldwide Express Plus',
          },
          {
            ServiceCode: '65',
            ServiceCodeDesc: null,
            CarrierCode: 'UPS',
            MaxCoverage: 0,
            ServiceName: 'Worldwide Saver',
          },
        ];
        break;
      case 'fedex':
        serviceTypes = [
          {
            ServiceCode: '03-DOM',
            ServiceCodeDesc: null,
            CarrierCode: 'FEDEX',
            MaxCoverage: 0,
            ServiceName: '2 Day',
          },
          {
            ServiceCode: 'EU-DOM',
            ServiceCodeDesc: null,
            CarrierCode: 'FEDEX',
            MaxCoverage: 0,
            ServiceName: 'Europe 1st',
          },
          {
            ServiceCode: '20',
            ServiceCodeDesc: null,
            CarrierCode: 'FEDEX',
            MaxCoverage: 0,
            ServiceName: 'Express Saver',
          },
          {
            ServiceCode: 'FXFE',
            ServiceCodeDesc: null,
            CarrierCode: 'FEDEX',
            MaxCoverage: 0,
            ServiceName: 'FedEx Freight',
          },
          {
            ServiceCode: '06-DOM',
            ServiceCodeDesc: null,
            CarrierCode: 'FEDEX',
            MaxCoverage: 0,
            ServiceName: 'First Overnight',
          },
          {
            ServiceCode: '92',
            ServiceCodeDesc: null,
            CarrierCode: 'FEDEX',
            MaxCoverage: 0,
            ServiceName: 'Ground',
          },
          {
            ServiceCode: 'CA-DOM',
            ServiceCodeDesc: null,
            CarrierCode: 'FEDEX',
            MaxCoverage: 0,
            ServiceName: 'Intra-Canada',
          },
          {
            ServiceCode: '01-DOM',
            ServiceCodeDesc: null,
            CarrierCode: 'FEDEX',
            MaxCoverage: 0,
            ServiceName: 'Priority Overnight',
          },
          {
            ServiceCode: '05',
            ServiceCodeDesc: null,
            CarrierCode: 'FEDEX',
            MaxCoverage: 0,
            ServiceName: 'Standard Overnight',
          },
          {
            ServiceCode: '03-INT',
            ServiceCodeDesc: null,
            CarrierCode: 'FEDEX',
            MaxCoverage: 0,
            ServiceName: 'International Economy',
          },
          {
            ServiceCode: '06-INT',
            ServiceCodeDesc: null,
            CarrierCode: 'FEDEX',
            MaxCoverage: 0,
            ServiceName: 'International First',
          },
          {
            ServiceCode: '01-INT',
            ServiceCodeDesc: null,
            CarrierCode: 'FEDEX',
            MaxCoverage: 0,
            ServiceName: 'International Priority',
          },
        ];
        break;
      default:
        break;
    }

    return of(serviceTypes);
  }
}
