export const WorldShipShipmentHeaders = {
  ShipmentInformationVoidIndicator: {
    EN: 'ShipmentInformationVoidIndicator',
    DE: 'SendungsinformationenStornoanzeige',
  },
  ShipmentInformationServiceType: {
    EN: 'ShipmentInformationServiceType',
    DE: 'SendungsinformationenServiceart',
  },
  ShipmentInformationShipperNumber: {
    EN: 'ShipmentInformationShipperNumber',
    DE: 'SendungsinformationenKundennummer',
  },
  ShipmentInformationNumberofPackages: {
    EN: 'ShipmentInformationNumberofPackages',
    DE: 'SendungsinformationenAnzahlderPakete',
  },
  ShipToCompanyorName: {
    EN: 'ShipToCompanyorName',
    DE: 'VersendenanFirmaoderName',
  },
  ShipToAttention: {
    EN: 'ShipToAttention',
    DE: 'VersendenanzuHändenvon',
  },
  ShipToAddress1: {
    EN: 'ShipToAddress1',
    DE: 'VersendenanAdresse1',
  },
  ShipToAddress2: {
    EN: 'ShipToAddress2',
    DE: 'VersendenanAdresse2',
  },
  ShipToAddress3: {
    EN: 'ShipToAddress3',
    DE: 'VersendenanAdresse3',
  },
  ShipToCountryTerritory: {
    EN: 'ShipToCountryTerritory',
    DE: 'VersendenanLandGebiet',
  },
  ShipToPostalCode: {
    EN: 'ShipToPostalCode',
    DE: 'VersendenanPostleitzahl',
  },
  ShipToCityorTown: {
    EN: 'ShipToCityorTown',
    DE: 'VersendenanStadtoderOrt',
  },
  ShipToStateProvinceCounty: {
    EN: 'ShipToStateProvinceCounty',
    DE: 'VersendenanStaatProvinz',
  },
  ShipToTelephone: {
    EN: 'ShipToTelephone',
    DE: 'VersendenanTelefon',
  },
  ShipFromCompanyorName: {
    EN: 'ShipFromCompanyorName',
    DE: 'VersendenvonFirmaoderName',
  },
  ShipFromUPSAccountNumber: {
    EN: 'ShipFromUPSAccountNumber',
    DE: 'VersendenvonUPSKunden',
  },
  PackageVoidIndicator: {
    EN: 'PackageVoidIndicator',
    DE: 'PaketStornoanzeige',
  },
  PackagePackageType: {
    EN: 'PackagePackageType',
    DE: 'PaketPakettyp',
  },
  PackageWeight: {
    EN: 'PackageWeight',
    DE: 'PaketGewicht',
  },
  PackageTrackingNumber: {
    EN: 'PackageTrackingNumber',
    DE: 'PaketKontrollnummer',
  },
  PackageReference1: {
    EN: 'PackageReference1',
    DE: 'PaketReferenz1',
  },
  PackageReference2: {
    EN: 'PackageReference2',
    DE: 'PaketReferenz2',
  },
  PackageReference3: {
    EN: 'PackageReference3',
    DE: 'PaketReferenz3',
  },
  ShipmentInformationCollectiondate: {
    EN: 'ShipmentInformationCollectiondate',
    DE: 'SendungsinformationenAbholdatum',
  },
  ThirdPartyUPSAccountNumber: {
    EN: 'ThirdPartyUPSAccountNumber',
    DE: 'DritteUPSKunden',
  },
  ThirdPartyUPSAccount: {
    EN: 'ThirdPartyUPSAccount',
    DE: 'DritteUPSKunden',
  },
};

