import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormGroup } from '@angular/forms';
import { WorldShipUploadService } from '../services/world-ship-upload.service';
import { ICarrierService } from '../../ship/models/carrier-service.interface';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
import { User } from '../../shared/services/user/models/user.model';
import { UserService } from '../../shared/services/user/user.service';

@Component({
  selector: 'upsc-shipments-preview',
  templateUrl: './shipments-preview.component.html',
  styleUrls: ['./shipments-preview.component.scss'],
})
export class ShipmentsPreviewComponent implements OnInit, AfterViewInit {
  @Input() formGroup: UntypedFormGroup;
  @Input() user: User;

  public serviceTypes: ICarrierService[];
  public worldShipShipmentsControls: AbstractControl[];
  public isServiceTypesLoading = true;

  public dtOptions: any = {};
  public dtTrigger: Subject<any> = new Subject<any>();

  constructor(private worldShipUploadService: WorldShipUploadService,
              private userService: UserService) {
    // TODO: check where to get the carrier code.
    this.worldShipUploadService.getServiceTypes('UPS')
      .subscribe(
        serviceTypes => this.handleGetServiceTypesSuccess(serviceTypes),
        err => this.handleGetServiceTypesFailure(err),
      );
  }

  public ngOnInit() {
    this.worldShipShipmentsControls = (<UntypedFormArray>this.formGroup.controls.worldShipShipments).controls;

    this.dtOptions = {
      // retrieve: true,
      autoWidth: false,
      searching: false,
      info: false,
      paging: false,
      ordering: false,
      responsive: false,
    };
  }

  public ngAfterViewInit() {
    this.dtTrigger.next(null);
  }

  private handleGetServiceTypesSuccess(serviceTypes: ICarrierService[]) {
    this.serviceTypes = serviceTypes;
    this.isServiceTypesLoading = false;
  }

  private handleGetServiceTypesFailure(err) {
    // TODO: log it!
    console.warn(err);

    // TODO: check where to get the carrier code.
    this.worldShipUploadService.getServiceTypesFixed('UPS')
      .subscribe(
        serviceTypes => this.handleGetServiceTypesFixedSuccess(serviceTypes),
        error => this.handleGetServiceTypesFixedFailure(error),
      );
  }

  private handleGetServiceTypesFixedSuccess(serviceTypes: ICarrierService[]) {
    this.serviceTypes = serviceTypes;
    this.isServiceTypesLoading = false;
  }

  private handleGetServiceTypesFixedFailure(err) {
    // TODO: log it!
    console.error(err);
    this.isServiceTypesLoading = false;
  }

  public removeShipment(event, i) {
    event.preventDefault();

    const controls = <UntypedFormArray>this.formGroup.controls.worldShipShipments;
    controls.removeAt(i);
  }

  public getServiceValue(serviceName: string) {
    return _.startCase(_.toLower(serviceName));
  }

  public getDisplayedServiceName(serviceName: string) {
    // [MV3-1969] Rename services for DE users ony.
    // [MV3-1919] Rename services for DE/GB users only.
    if (!this.userService.isDEGBUser(this.user)) {
      return serviceName;
    }

    switch (serviceName) {
      case 'Worldwide Saver':
        return 'UPS Express Saver';
      case 'Worldwide Express':
        // [MV3-1969] Change service name for DE/GB users.
        // [MV3-3188] Rename it from 'UPS Express 12:00' to just 'UPS Express'.
        // return 'UPS Express 12:00';
        return 'UPS Express';
      case 'Standard':
        // [MV3-1972] Change service name for DE/GB users.
        return 'UPS Standard';
      default:
        return serviceName;
    }
  }
}
